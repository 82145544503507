$(document).foundation();

$(document).ready(function () {
    // utils
    initVerticalCenter();
    initAnimateInView();
    inlineSVGs([
	    '.footer__social img'
    ]);

    // global
	initHeader();
    initForms();
    initSliders();
    initVideoSlider();
	initPopups();
    initOffCanvas();
    initAccordions();
    initEvents();
    initBooking();
    initDining();
    initVideos();

	// template specific
    initGallery();
    hideLandingBorder();
    setIntroHeadingStyle();
    initSpaBooking();

});


 //video play pause

    function initVideos() {


        var videos = $('.video-frame')
        var playBtns = $('.home-video-logo > .play, .play-btn')
        var pauseBtn = $('.pause-btn')
        var playLrgeBtn = $('.home-video-logo > .play')
        var playSmllBtn = $('.play-btn')
        var volOnBtn = $('.volOn-btn')
        var volOffBtn = $('.volOff-btn')


        function createVideo(video, Vimeo) {
            var player = new Vimeo.Player(video)
            return player
        }

        function initVimeo(videos) {
            var playerArr = [];

            if (videos.length === 0) return;
            if (videos.length === 1) {
                var player = createVideo(videos, Vimeo)
                playerArr.push(player);
            }

            videos.each(function () {
                var player = createVideo($(this), Vimeo)
                playerArr.push(player)
            })
            return playerArr;
        }


        function getVideoToPlay(videos, value) {
            var targetValue = value.toString();
            var filteredVideo;
            videos.forEach(function (item) {
                if (item.element.getAttribute('data-video') !== targetValue ) return;
                filteredVideo = item;
            })
            return filteredVideo
        }

        function getTargetedIcon(icons, value) {
            var targetIcon;
            icons.each(function () {
                if ($(this).data('video') !== value ) return;
                targetIcon = $(this);
            })
            return targetIcon
        }

        var player = initVimeo(videos)

        playBtns.each(function () {
            $(this).on('click', function(e) {
            var value = $(e.target).data('video');
            var targetVideo = getVideoToPlay(player, value);
            var pause = getTargetedIcon(pauseBtn, value);
            var playLarge = getTargetedIcon(playLrgeBtn, value);
            var playSmall = getTargetedIcon(playSmllBtn, value);
            targetVideo.play();
            pause.addClass('on')
            playSmall.removeClass('on')
            playLarge.hide();
            })
        })

        pauseBtn.each(function () {
            $(this).on('click', function(e) {
            var value = $(e.target).data('video');
            var targetVideo = getVideoToPlay(player, value);
            var playSmall = getTargetedIcon(playSmllBtn, value);
            targetVideo.pause();
            $(this).removeClass('on')
            playSmall.addClass('on')
            })
        })

        volOnBtn.each(function () {
            $(this).on('click', function (e) {
                var value = $(e.target).data('video');
                var targetVideo = getVideoToPlay(player, value);
                var volOff = getTargetedIcon(volOffBtn, value);
                targetVideo.setVolume(0.66);
                $(this).removeClass('on')
                volOff.addClass('on');
            })
        })

        volOffBtn.each(function () {
            $(this).on('click', function (e) {
                var value = $(e.target).data('video');
                var targetVideo = getVideoToPlay(player, value);
                var volOn = getTargetedIcon(volOnBtn, value);
                targetVideo.setVolume(0);
                $(this).removeClass('on')
                volOn.addClass('on')
            })
        })

    }


function initVerticalCenter() {
    jQuery('.vertical-center').each(function () {
        jQuery(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
    });
}



// https://stackoverflow.com/questions/24933430/img-src-svg-changing-the-fill-color
function inlineSVGs(inputValue) {
    var selectorString = '';
    if (typeof inputValue === "object") {
        selectorString = inputValue.join(',');
    } else {
        selectorString = inputValue;
    }

    jQuery(selectorString).each(function () {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        if (imgURL.indexOf('.svg') > -1) {
	        jQuery.get(imgURL, function (data) {
	            // Get the SVG tag, ignore the rest
	            var $svg = jQuery(data).find('svg');

	            // Add replaced image's ID to the new SVG
	            if (typeof imgID !== 'undefined') {
	                $svg = $svg.attr('id', imgID);
	            }
	            // Add replaced image's classes to the new SVG
	            if (typeof imgClass !== 'undefined') {
	                $svg = $svg.attr('class', imgClass + ' replaced-svg');
	            }

	            // Remove any invalid XML tags as per http://validator.w3.org
	            $svg = $svg.removeAttr('xmlns:a');

	            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
	            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
	                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
	            }

	            // Replace image with new SVG
	            $img.replaceWith($svg);
	        }, 'xml');
        }
    });
}



function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}


function initAnimateInView() {
    jQuery(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
    animateElementsInView();
}

function animateElementsInView() {
    jQuery('[data-animate-in-view]').each(function () {
        if ( jQuery(this).hasClass('in-view') ) {
            return;
        }

        if ( isElementInViewport(jQuery(this)) ) {
            jQuery(this).addClass('in-view'); // log that this element has been seen

            jQuery(this).addClass( jQuery(this).data('animate-in-view') ); // apply the animation class
        }
    });
}

function isElementInViewport (el) {
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    var t = rect.y;
    var b = window.innerHeight - rect.y - rect.height;
    var h = window.innerHeight;

    if (
        ( (t >= 0) && (t <= h) ) ||
        ( (b >= 0) && (b <= h) )
    ) {
        return true;
    } else {
        return false;
    }
}


function initHeader() {
    if ( jQuery('.slider').length === 0 ) {
        jQuery('.header').addClass('header--scrolled');
        jQuery('body').addClass('no-slider');
        return;
    }

 	setHeaderSize();
	jQuery(window).on('scroll', setHeaderSize);

	if ( jQuery('.animation, .slider').length === 0 ) {
		jQuery('main').addClass('no-slider');
	}
}

function setHeaderSize() {
	if (window.pageYOffset > 150) {
		jQuery('.header').addClass('header--scrolled');
	} else {
		jQuery('.header').removeClass('header--scrolled');
	}
}

function initSliders() {
    jQuery('.slider__inner').reformSlider({
        speed: 4000,
        animationSpeed: 1000,
        arrows: false,
        pagination: jQuery('.slider').hasClass('slider--has-dots'),
        paginationArrows: false
    });

    jQuery('.home-listing__slider').slick({
        fade: true,
        arrows: true,
        lazyLoad: 'progressive'
    });


    jQuery('.landing__slider').slick({
        fade: true,
        arrows: true,
        lazyLoad: 'progressive'
    });


    jQuery('.room-gallery').slick({
        fade: true,
        arrows: true,
        lazyLoad: 'progressive'
    });

    jQuery('.fd-details__slider').slick({
        fade: true,
        arrows: true,
        appendArrows: jQuery('.fd-details__sidebar-slider-nav'),
        lazyLoad: 'progressive'
    })

    jQuery('.fd-details__sidebar-slider-nav .slick-prev').on('mouseenter', function() {
        jQuery('span.prev').addClass('on');
    })

    jQuery('.fd-details__sidebar-slider-nav .slick-prev').on('mouseleave', function() {
        jQuery('span.prev').removeClass('on');
    })

    jQuery('.fd-details__sidebar-slider-nav .slick-next').on('mouseenter', function() {
        jQuery('span.next').addClass('on');
    })

    jQuery('.fd-details__sidebar-slider-nav .slick-next').on('mouseleave', function() {
        jQuery('span.next').removeClass('on');
    })
}

function initVideoSlider () {
    var videos = $('.slider__slide iframe')
    if (videos.length === 0) return;

    var video = setVideo(videos)
    var player = new Vimeo.Player(video);
    handleOverlay(video, player)
    handleVideoBtns( player)

    function setVideo(videos) {
        var desktop;
        var mobile;
        var video;

        // if no responsive video set, return
        if ($('.iframe-wrap').data('responsive-videos') === false || typeof $('.iframe-wrap').data('responsive-videos') === 'undefined') {
            video = videos
            return video[0]
        }

        // set desktop, mobile iFrame
        videos.each(function () {
            if ($(this).data('video') === 'desktop') {
                desktop = $(this)
                return
            }
            mobile = $(this)
        })

        // check window size
        var isSmall = window.matchMedia('(max-width: 760px)');
        if (isSmall.matches) {
           mobile.attr('src', mobile.data('src'))
           video = mobile
           return video[0]
        }
        desktop.attr('src', desktop.data('src'))
        video = desktop
        return video[0]
    }

    function handleOverlay(video, player) {

        var overlay = document.querySelector('.video-placeholder')
        if (window.innerWidth < 840) {
            setTimeout(function() {
                sizeVideo(video)
                // overlay.style.transition = 'all 0.8s ease-in-out';
                overlay.style.opacity = 0;
            }, 2000);
            return
        }

        player.on('play', function() {
            sizeVideo(video)
            setTimeout(function() {
                // overlay.style.transition = 'all 0.8s ease-in-out';
                overlay.style.opacity = 0;
                overlay.style.pointerEvents = 'none';
            }, 500);
        });
    }

    function handleVideoBtns(player) {
        var videoBar = document.querySelector('.sound-bar')
        var volOnOff= document.querySelector('.volume-on-off-text')

        var isSound = false;

        function toggleSound (state, player) {
            if (!state) {
                volOnOff.innerHTML='on'
                player.setVolume(0);
            } else {
                volOnOff.innerHTML='off'
                player.setVolume(0.66);
            }
        }

        videoBar.addEventListener('click', function() {
            isSound=!isSound
            toggleSound(isSound, player)
        })
    }

    function sizeVideo(video) {
        coverViewport(video);
        window.addEventListener('resize', function () {
            coverViewport(video)
        });
    }

    function coverViewport(element, scaleToParent) {
        if (typeof scaleToParent === 'undefined') { scaleToParent = false; }

        var viewportWidth, viewportHeight;
        if (scaleToParent) {
            viewportWidth = element.parentElement.clientWidth;
            viewportHeight = element.parentElement.clientHeight;
        } else {
            viewportWidth = window.innerWidth;
            viewportHeight = window.innerHeight;
        }

        var elementWidth = element.getAttribute('width');
        var elementHeight = element.getAttribute('height');

        // Aspect ratio = height / width
        var viewportRatio = viewportHeight / viewportWidth;
        var videoRatio = elementHeight / elementWidth;

        // console.log('viewportHeigt: ', viewportHeight )
        // console.log('viewportWidth: ', viewportWidth )
        // console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


        var newWidth = (elementWidth / elementHeight) * viewportHeight
        var newHeight = (elementHeight / elementWidth) * viewportWidth;
        var newTop = 0;


        if (viewportRatio > videoRatio) {
            // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
            // console.log('crop left and right');
            element.style.height = viewportHeight +'px';
            element.style.width = newWidth +'px';
            element.style.left = '-' + (newWidth - viewportWidth) / 2 +'px';
            element.style.top = '0';
        } else if (viewportRatio < videoRatio) {
            // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
            // console.log('crop top and bottom');
            element.style.width = viewportWidth +'px';
            element.style.height = newHeight +'px';
            element.style.left = '0';
            element.style.top = '-' + (newHeight - viewportHeight) / 2 +'px';
        } else {
            // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
            // console.log('no crop');
            element.style.height = viewportHeight +'px';
            element.style.width = viewportWidth +'px';
            element.style.left = '0';
            element.style.top = '0';
        }
    }
}

function initForms() {
    jQuery('.validate-this').each(function (i, form) {
        jQuery(form).validate();
    });


    function setYearOnNewsletter() {
        var day = $('.day-dob')
        var month = $('.month-dob')
        var year = $('.year-dob')

        day.on('change', function () {
            if ($(this).val() === '') return
            $('.year-dob option[value=1901]').attr('selected','selected');
        })
    }
    setYearOnNewsletter()
}



function initPopups() {
    jQuery('a[href="#features"]').on('click', function () {
        jQuery('#features').foundation('open');
        return false
    });

    var popup = $('#popup')

    if ( popup.length === 0 ) {
        return
    }

    var popupDelay = popup.data('popup-delay') || 0;
    var popupKeepHiddenFor = popup.data('popup-keep-hidden-for-n-days') || 0;
    var isUserInteraction = popup.data('popup-on-user-interaction')


    if (Cookies.get('hasSeenPopup')) {
        return
    }

    if (isUserInteraction && $(window).innerWidth() > 640) {
        var isActive = false;

        setTimeout(function () {
            isActive = true
        }, 5000)


        $('body').mouseleave(function () {
            if (isActive) {
                if (Cookies.get('hasSeenPopup')) {
                    return
                }
                showPopup(popup, popupKeepHiddenFor, 100)
                $(this).off('mouseleave')
            }
        })
        return
    }

    if (isUserInteraction && $(window).innerWidth() < 640) {
        return
    }

    showPopup(popup, popupKeepHiddenFor, popupDelay)

    function showPopup (popup, popupKeepHiddenFor, popupDelay) {
        setTimeout(function () {
            popup.foundation('open');

            if (popupKeepHiddenFor > 0) {
                Cookies.set('hasSeenPopup', 'true', {expires: popupKeepHiddenFor});
            }
        }, popupDelay)
    }
}


function initGallery() {
    if ( jQuery('.gallery').length > 0 ) {
        alignGalleryGrid();
        jQuery(window).on('resize', alignGalleryGrid);
    }


    jQuery('.gallery__category a').on('click', function () {
        // Dynamically load the gallery slider into a reveal popup

        var $modal = jQuery('<div id="gallery-modal" class="gallery-modal full reveal loading" data-reveal><button class="gallery-modal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div class="gallery-modal__slider"></div></div>');

        jQuery('body').append($modal);

        $modal.find('.gallery-modal__slider').load(jQuery(this).attr('href'), function () {
            $modal.removeClass('loading').find('.gallery-modal__slider').slick({
                arrows: true,
                fade: true
            });
        });

        jQuery('#gallery-modal').foundation();
        $modal.foundation('open');

        $modal.on('closed.zf.reveal', function () {
            $modal.remove();
        });

        return false;
    });
}

// Move the rightmost column of the gallery grid up a bit, but only on medium and up
function alignGalleryGrid() {
    if ( jQuery(window).innerWidth() >= 640 ) {
        jQuery('.gallery__category:nth-child(2n)').css('transform', 'translatey(-' + jQuery('.gallery__intro').outerHeight() + 'px)');
    } else {
        jQuery('.gallery__category').attr('style', '');
    }
}


function initOffCanvas() {
    //$('#offCanvas').foundation('open');

    jQuery('.off-canvas__primary > ul > li').each(function () {
        // Automatically add the "Overview" links to the landing page
        if ( jQuery(this).find('ul').length > 0 ) {
            var overviewLink = jQuery(this).find('a').first().attr('href');
            jQuery(this).find('ul').prepend('<li><a href="' + overviewLink + '">' + jQuery(this).find('a').first().text() + ' Overview</a></li>');
        }
    });


    jQuery('.off-canvas__primary > ul > li > a').on('click', function () {
        if ( jQuery(this).next('ul.off-canvas__primary-dropdown').length > 0 ) { // only trigger if there are sub pages to show
            if ( jQuery(this).parent().hasClass('open') ) {
                // second click of main item allows clicking directly through
                return true;
            } else {
                // close any other open items
                jQuery('.off-canvas__primary > ul > li.open').removeClass('open').find('ul.off-canvas__primary-dropdown').slideUp();

                // and open the new one
                jQuery(this).parent().addClass('open');
                jQuery(this).next().slideDown();
            }
            return false; // prevent navigating
        }
    });
}

function initAccordions() {
    jQuery('.location__direction-accordion').accordiom({
        showFirstItem: false
    })
}

function initBooking() {
    if ( $('.booking-bar').length === 0 ) return;


    var isWhyBookingActive = false;

    function handleChange(state) {
        if (state) {
            jQuery('.header').css('z-index', 2000)
        } else {
            jQuery('.header').css('z-index', 3000)
        }
    }

    function isSliderPresent () {
        if (jQuery('.slider').length !== 0) return
        jQuery('.booking-bar__why-book-content').attr('style', 'opacity: 1 !important; position: absolute; display:block !important; bottom: -323px !important;');
    }

    jQuery('.booking-bar__form').each(function () {
        initDateSelection(this);
    });

    jQuery('.booking-bar__why-book-close').on('click', function () {
       jQuery('.booking-bar__why-book-content').slideUp();
       isWhyBookingActive = false;
       handleChange(isWhyBookingActive);
    });

    jQuery('.booking-bar__why-book-button button').on('click', function () {
        jQuery('.booking-bar__why-book-content').slideToggle();
        isWhyBookingActive = !isWhyBookingActive;
        handleChange(isWhyBookingActive);
        isSliderPresent()
    });


    var bookingLink = $('.booking-bar__form form').attr('action');
    if (typeof bookingLink === 'undefined') return;
    // Split our URL parameters into hidden inputs
    if ( bookingLink.indexOf('?') ) {
        var bookingLinkParams = bookingLink.split('?')[1].split('&');
        $('.booking-bar__form form').attr('action', bookingLink.split('?')[0]);

        for (var i=0; i < bookingLinkParams.length; i++) {
            var paramName = bookingLinkParams[i].split('=')[0];
            var paramValue = bookingLinkParams[i].split('=')[1];

            if ( (typeof paramName !== 'undefined') && (typeof paramValue !== 'undefined') ) {
                $('.booking-bar__form form').prepend('<input type="hidden" name="' + paramName + '" value="' + paramValue + '" />');
            }
        }
    }

}

function initDateSelection(bookingContainer) {
    var nowTemp = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(nowTemp.getDate() + 1);

    var $checkInField = $(bookingContainer).find('.check-in');
    var $checkOutField = $(bookingContainer).find('.check-out');

    $checkInField.on('hide.datepicker', function () {
        var checkInDate  = $checkInField.datepicker('getDate');
        var checkOutDate = $checkOutField.datepicker('getDate');
        var dayAfterCheckIn = new Date(checkInDate);
        dayAfterCheckIn.setDate(checkInDate.getDate() + 1);

        // Ensure checkout comes after checkin.
        // Otherwise it all gets a bit Restaurant at the End of the Universe-ey.
        if ( checkInDate >= checkOutDate ) {
            $checkOutField.datepicker('setDate', dayAfterCheckIn);
        }

        $checkOutField.datepicker('setStartDate', dayAfterCheckIn);

        $checkOutField[0].focus();
    });

    var checkin = $checkInField.datepicker({
        format: 'dd/mm/yy',
        //autoPick: true,
        autoHide: true,
        date: null,
        startDate: nowTemp
    });

    var checkout = $checkOutField.datepicker({
        format: 'dd/mm/yy',
        //autoPick: true,
        autoHide: true,
        date: null,
        startDate: tomorrow
    });

    // Swap the date format into year-month-day for Synxis
    $(bookingContainer).find('form').on('submit', function () {
        var checkInDate  = $checkInField.datepicker('getDate');
        var checkOutDate = $checkOutField.datepicker('getDate');

        $(bookingContainer).find('[name="fromDate"]').val(  checkInDate.getFullYear() + '-' + pad((checkInDate.getMonth()*1 + 1*1), 2) + '-' + pad((checkInDate.getDate()), 2)   );
        $(bookingContainer).find('[name="toDate"]').val( checkOutDate.getFullYear() + '-' + pad((checkOutDate.getMonth()*1 + 1*1), 2) + '-' + pad((checkOutDate.getDate()), 2) );

        if ( $('body').hasClass('theme-barnsdale-lodge') ) {
            $(bookingContainer).find('[name="fromDate"]').attr('name', 'fromDate');
            $(bookingContainer).find('[name="toDate"]').attr('name', 'toDate');
            $(bookingContainer).find('[name="adult"]').attr('name', 'adults');
        }
    });
}

function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function initDining() {
    jQuery('a[href="#book-a-table"]').on('click', function () {

		var $modal;

        if ( $('body.theme-mitre').length > 0 ) {

            var modal = '<div id="booking-modal" class="reveal" data-reveal><button class="reveal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div><script type="text/javascript" src="//www.opentable.co.uk/widget/reservation/loader?rid=215847&rid=215844&type=multi&theme=standard&iframe=true&domain=couk&lang=en-GB&newtab=false&ot_source=Restaurant%20website"></script></div></div>';

        } else if ( $('body.theme-retreat').length > 0 ) {
            var modal = '<div id="booking-modal" class="reveal" data-reveal><button class="reveal__close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><div><div id="rd-widget-frame" style="max-width: 600px; margin: auto;"></div><input id="rdwidgeturl" name="rdwidgeturl" value="Book online with ResDiary" type="hidden"><script type="text/javascript" src="https://booking.resdiary.com/bundles/WidgetV2Loader.js"></script></div></div>';

        }


		$modal = jQuery( modal );

		jQuery('body').append($modal);

		jQuery('#booking-modal').foundation();
		$modal.foundation('open');

		$modal.on('closed.zf.reveal', function () {
			$modal.remove();
		});

		// Why is the Foundation close button not working, and why do I have to make my own!?!?!
		/*$('.gallery__modal-close').on('click', function () {
			$modal.fadeOut(function () {
				$modal.remove();
			});
		});*/

		return false;

    });

      if ($(".slider").length < 1) {
        $(".booking-bar__book-a-table").css("transform", "translateY(0)");
      }

}

function hideLandingBorder() {
    jQuery('.landing__content').each(function() {
        if( jQuery(this).children().length < 2 ) {
            jQuery(this).find('.landing__description').css('border', 'none');
        }
    })
}

function setIntroHeadingStyle() {
    if( jQuery('.intro--dining').length >=1 ) {
        jQuery('.intro__heading h1').addClass('image');
    }
}
function initSpaBooking() {
    var $ = jQuery;
    if (!$('body').hasClass('theme-retreat')) return

    var callBtn = $('.book-spa')
    var spaOptions = $('.book-spa-wrapper')
    var spaOptionsMob= $('.book-spa-wrapper-mobile')
    var spaGuestBtn = $('.spa-guest-btn')
    var spaGuestBtnOffers = $('.spa-guest-btn-offers')
    var spaGuestBtnMob = $('.spa-guest-btn-m')
    var spaMemberBtn = $('a[href="#spa-member"]')
    var spaTreatmentBtn = $('a[href="#spa-treatment"]')
    var spaFacilityBtn = $('a[href="#spa-facility"]')
    var hotelGuestBtn = $('a[href="#hotel-guest"]')


    var height = {
        mobile: "600px",
        desktop: "400px"
    }

    var modals = {
        spaTreatment:'<div id="booking-spa-treatment" class="reveal" style="overflow-y:initial; margin-top:70px !important;" data-reveal><button class="reveal__close" data-close aria-label="Close modal" type="button" style="top: -40px; right:0;"><span aria-hidden="true">Close</span></button><div><iframe src="https://online.premiersoftware.co.uk/Book-Online-3G-V2/index2.php?i=93b65ec05eee2d9288a793212ec9b5c1&siteID=2" style="border:0px # none;" name="myiFrame" frameborder="0" marginheight="0px" marginwidth="0px" height=' + renderHeight(height) + ' width="600px" allowfullscreen></iframe></div></div>',
        spaFacility:'<div id="booking-spa-facility" class="reveal" style="overflow-y:initial; margin-top:70px !important;" data-reveal><button class="reveal__close" data-close aria-label="Close modal" type="button" style="top: -40px; right:0;"><span aria-hidden="true">Close</span></button><div><iframe src="https://online.premiersoftware.co.uk/Book-Online-Facility-3G-V2/index.php?i=93b65ec05eee2d9288a793212ec9b5c1&siteID=2" style="border:0px # none;" name="myiFrame" frameborder="0" marginheight="0px" marginwidth="0px" height=' + renderHeight(height) + ' width="600px" allowfullscreen></iframe></div></div>',
        spaMember: '<div id="booking-spa-member" class="reveal" style="overflow-y:initial; margin-top:70px !important;" data-reveal><button class="reveal__close" data-close aria-label="Close modal" type="button" style="top: -40px; right:0;"><span aria-hidden="true">Close</span></button><div><iframe src="https://online.premiersoftware.co.uk/ClientPortal/?i=93b65ec05eee2d9288a793212ec9b5c1&siteID=2" style="border:0px # none;" name="myiFrame" frameborder="0" marginheight="0px" marginwidth="0px" height=' + renderHeight(height) + ' width="600px" allowfullscreen></iframe></div></div>',
        hotelGuest: '<div id="booking-hotel-guest" class="reveal" style="overflow-y:initial; margin-top:70px !important;" data-reveal><button class="reveal__close" data-close aria-label="Close modal" type="button" style="top: -40px; right:0;"><span aria-hidden="true">Close</span></button><div><iframe src="https://online.premiersoftware.co.uk/ReservationPortal/index2.php?i=93b65ec05eee2d9288a793212ec9b5c1&siteID=2" style="border:0px # none;" name="myiFrame" frameborder="0" marginheight="0px" marginwidth="0px" height=' + renderHeight(height) + ' width="600px" allowfullscreen></iframe></div></div>',
    }

    var state = {
        isMain: false,
        isMobile: false,
        isSeparate: false,
        isGuestActive: false,
    }

    function renderHeight(height) {
        var winHeight = $(window).height()

        if (winHeight > 700) {
            return height.mobile
        } else {
            return height.desktop
        }
    }

    function toggleContainer(container, state) {
        if (!state) {
            container.addClass('active')
        } else {
            container.removeClass('active')
        }
    }

    function getModal(modal, id) {
        $modal = jQuery( modal );
        $('body').append($modal)
        $('#' + id).foundation();
		$modal.foundation('open');
		$modal.on('closed.zf.reveal', function () {
			$modal.remove();
        })
    }


    callBtn.on('click', function () {
        if ($(this).data('part') === 'separate') {
            toggleContainer(spaOptions, state.isSeparate)
            state.isSeparate = !state.isSeparate
            return
        }
        if ($(this).data('part') === 'mobile') {
            toggleContainer(spaOptionsMob, state.isMobile)
            state.isMobile = !state.isMobile
            return
        }
        toggleContainer(spaOptions, state.isMain)
        state.isMain = !state.isMain
    })



    spaGuestBtn.on('click', function () {
        $('.spa-guest-section').toggleClass('active')
        $('.book-spa-wrapper').toggleClass('spa-guest-open')
        $(this).toggleClass('open')
    })

    spaGuestBtnOffers.on('click', function () {
        $('.spa-guest-section').toggleClass('active')
        $('.book-spa-wrapper').toggleClass('spa-guest-open')
        $(this).toggleClass('open')
    })

    spaGuestBtnMob.on('click', function () {
        $('.spa-guest-section-m').toggleClass('active')
        $('.book-spa-wrapper-mobile').toggleClass('spa-guest-open')
        $(this).toggleClass('open')
    })

    spaTreatmentBtn.on('click', function () {
        getModal(modals.spaTreatment, 'booking-spa-treatment')
    })

    spaFacilityBtn.on('click', function () {
        getModal(modals.spaFacility, 'booking-spa-facility')
    })


    spaMemberBtn.on('click', function () {
        getModal(modals.spaMember, 'booking-spa-member')
    })

    hotelGuestBtn.on('click', function () {
        getModal(modals.hotelGuest, 'booking-hotel-guest')
    })

}


function initEvents() {
    var ctaBtns = $('.events__item .cta-wrapper .button')
    var nextBtns = $('.revealed-item__inner .next')
    var prevBtns = $('.revealed-item__inner .prev')
    var smlFilterToggle = $('.filter .mobile-toggle')
    var filterBtnsWrapper = $('.filter__buttons')
    var filterBtns = $('.filter__buttons button')



    prevBtns.first().hide();
    nextBtns.last().hide();


    if ($(window).innerWidth() < 640) {
        toggleSmallFilter(filterBtnsWrapper, smlFilterToggle, filterBtns);
        handleCatChange(smlFilterToggle, filterBtns)
    }
    openModals(ctaBtns);
    setNextPrevBtn(nextBtns, prevBtns)

    function toggleSmallFilter(wrapper, btn, catBtns) {
        var isActive = false;

        function handleStateChange (state, wrapper, btn) {
            if (!state) {
                wrapper.css('display', 'none');
                btn.removeClass('active');
            } else {
                wrapper.css('display', 'flex');
                btn.addClass('active');
            }
        }

        catBtns.each(function () {
            $(this).on('click', function () {
                isActive = !isActive;
                handleStateChange(isActive, wrapper, btn);
            })
        })

        btn.on('click', function () {
            isActive = !isActive;
            handleStateChange(isActive, wrapper, $(this));
        })
    }

    function openModals(btns) {
        btns.each(function (index) {
            $(this).on('click', function () {
                $('#show-' + index).foundation('open');
            })
        })
    }


    function handleCatChange(toggleBtn, catBtns) {
        catBtns.each(function () {
            $(this).on('click', function () {
                toggleBtn.html($(this).text())
            })
        })
    }

    function setNextPrevBtn(next, prev) {

        next.on('click', function () {
            var getitemIdNum = $(this).parents('.revealed-item').attr('id').
            split('-');
            var itemIdNum = Number(getitemIdNum[1])
            var nextId = itemIdNum + 1;
            $('#show-' + nextId).foundation('open');
            $('#show-' + itemIdNum).foundation('close');
        });

        prev.on('click', function () {
            var getitemIdNum = $(this).parents('.revealed-item').attr('id').split('-');
            var itemIdNum = Number(getitemIdNum[1])
            var prevId = itemIdNum -1;
            $('#show-' + prevId).foundation('open');
            $('#show-' + itemIdNum).foundation('close');
        });
    }
}
