jQuery(document).ready(function($){
  
  if ( $('.map__embed').length === 0 ) return;

  var latitude = $('.map__embed').data('map-center-coords').split(',')[0];
  var longitude = $('.map__embed').data('map-center-coords').split(',')[1];
  var zoomLevel = $('.map__embed').data('map-zoom') || 15;
  var markerIcon = {
    url: $('.map__embed').data('map-marker-url'),
    size: new google.maps.Size(119, 79),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(19, 79)
  };


  if ( $('.location__map').length > 0 ){
  
  var styledMap = new google.maps.StyledMapType(mitreMap ,{name: "Styled Map"});
  
  
  var map = new google.maps.Map( $('.map__embed')[0]  ,{
    center: new google.maps.LatLng(latitude, longitude),
    zoom: zoomLevel,
    scrollwheel: false,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: mitreMap
  }),
  marker = new google.maps.Marker({
    position: new google.maps.LatLng(latitude, longitude),
    icon: markerIcon,
    map: map
  }),
  info = new google.maps.InfoWindow({
    content: popupContent
  });
  //info.open(map, marker);
  
  //map.mapTypes.set('map_style', styledMap);
  //map.setMapTypeId('map_style');
  
  var old_v='',
  ds=new google.maps.DirectionsService(),
  dr=new google.maps.DirectionsRenderer();
  dr.setMap(map);
  dr.setPanel(jQuery('#map-directions-data').get(0));  
  
  }
});

var mitreMap = [
  {
    "featureType": "all",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "saturation": 36
      },
      {
        "color": "#333333"
      },
      {
        "lightness": 40
      }
    ]
  },
  {
    "featureType": "all",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#ffffff"
      },
      {
        "lightness": 16
      }
    ]
  },
  {
    "featureType": "all",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "lightness": 20
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#fefefe"
      },
      {
        "lightness": 17
      },
      {
        "weight": 1.2
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [
      {
        "lightness": 20
      },
      {
        "color": "#ede8d8"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#b0c7af"
      },
      {
        "lightness": 21
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "lightness": 21
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 17
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 29
      },
      {
        "weight": 0.2
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 18
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      },
      {
        "lightness": 16
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f2f2f2"
      },
      {
        "lightness": 19
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c7dbee"
      },
      {
        "lightness": 17
      }
    ]
  }
]